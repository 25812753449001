import React from "react";
import SEO from "../components/SEO.jsx";
import Header from "../partials/header/Header.jsx";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";

const PrivacyPolicy = () => {
  return (
    <React.Fragment>
      <SEO title="Tawanai || EnergyTech" />
      <Header />
      <Breadcrumb
        image="images/bg/bg1.webp"
        title="Tawanai"
        content="Privacy Policy"
        contentTwo="Statement"
      />

      <div style={{ padding: "7%" }}>
        <div>
          <p style={{ textAlign: "center", color: "white" }}>.</p>
          <h1 style={{ textAlign: "center", padding: "3%" }}>
            Privacy Statement
          </h1>
          <p>
            At Tawanai, we are deeply committed to safeguarding your personal
            data and ensuring your privacy. We strictly comply with all
            applicable Indian Data Protection laws, including the Information
            Technology (Reasonable Security Practices and Procedures and
            Sensitive Personal Data or Information) Rules, 2011 under the IT
            Act, 2000. This Privacy Statement outlines how we process and
            protect the personal data collected through our Website and
            services.
          </p>
        </div>

        <div style={{ marginTop: "2%" }}>
          <h3 style={{ marginBottom: "1%" }}>Processing of Personal Data</h3>
          <p>
            Tawanai processes personal data provided by you when you use our
            Website, products, or services. This data may include, but is not
            limited to:
          </p>
          <ul>
            <li>
              <strong>Personal Information:</strong> Name, email address, phone
              number, postal address, or other identifiers.
            </li>
            <li>
              <strong>Technical Information:</strong> IP address, browser
              details, and Website usage behaviour.
            </li>
            <li>
              <strong>Additional Data:</strong> Information voluntarily provided
              by you through forms, surveys, or communications.
            </li>
          </ul>
          <p>
            We collect this data to operate our business efficiently and offer
            you high-quality services.
          </p>
        </div>

        <div style={{ marginTop: "2%" }}>
          <h3 style={{ marginBottom: "1%" }}>
            Purposes of Processing Personal Data
          </h3>

          <ol>
            <li>
              <strong>Communication:</strong> To respond to your queries,
              provide support, and share updates.
            </li>
            <li>
              <strong>Improvement:</strong> To enhance the functionality,
              security, and performance of our Website.
            </li>
            <li>
              <strong>Customization:</strong> To deliver personalized services,
              recommendations, and content tailored to your preferences.
            </li>
            <li>
              <strong>Marketing:</strong> To send information about products,
              services, promotions, or offers that may interest you.
            </li>
            <li>
              <strong>Legal Compliance:</strong> To meet our legal and
              regulatory obligations.
            </li>
            <li>
              <strong>Analytics:</strong> To analyze Website usage and improve
              user experience.
            </li>
          </ol>
        </div>

        <div style={{ marginTop: "2%" }}>
          <h3>Your Rights Regarding Personal Data</h3>

          <ul>
            <li>
              <strong>Access and Rectification:</strong>
              <ol>
                <li>Request a copy of your personal data held by us.</li>
                <li>
                  Request correction of inaccurate or incomplete information.
                </li>
              </ol>
            </li>

            <li>
              <strong>Consent Management:</strong>
              <ol>
                <li>
                  Withdraw consent for direct marketing or promotional
                  communications by clicking the unsubscribe link in our emails
                  or contacting us.
                </li>
              </ol>
            </li>

            <li>
              <strong>Deletion of Data:</strong>
              <ol>
                <li>
                  Request the deletion of your personal data, subject to our
                  legal and business requirements.
                </li>
              </ol>
            </li>
          </ul>
        </div>
        <div style={{ marginTop: "2%" }}>
          <h3 style={{ marginBottom: "1%" }}>Disclosure of Personal Data</h3>
          <p>
            Tawanai does not share your personal data with third parties without
            your explicit consent, except in the following circumstances:
          </p>
          <ol>
            <li>
              <strong>Legal Requirements:</strong> When mandated by Indian laws,
              regulations, or court orders.
            </li>
            <li>
              <strong>Service Providers:</strong> Trusted third parties who
              assist in delivering our services (e.g., IT support, analytics
              providers).
            </li>
            <li>
              <strong>Emergency Situations:</strong> When necessary to protect
              the life, health, or safety of individuals or the community.
            </li>
          </ol>
          <p>
            We may also share aggregated and anonymized data for research,
            analytics, or business purposes, ensuring that individuals cannot be
            identified.
          </p>
        </div>
        <div style={{ marginTop: "2%" }}>
          <h3>Retention and Security of Personal Data</h3>
          <p>
            Tawanai retains personal data only as long as necessary to fulfill
            the purposes outlined in this Privacy Statement. Once the data is no
            longer needed, it will be securely destroyed in accordance with
            applicable laws.
          </p>

          <p>
            We implement industry-standard security practices, including
            encryption and access controls, to protect your data from
            unauthorized access, misuse, or disclosure. However, no method of
            transmission or storage is entirely secure, and we cannot guarantee
            absolute data security.
          </p>
        </div>
        <div style={{ marginTop: "2%" }}>
          <h3>Changes to the Privacy Statement</h3>
          <p>
            Tawanai reserves the right to update this Privacy Statement from
            time to time to reflect changes in legal, technical, or business
            developments. We encourage you to review this Privacy Statement
            periodically.
          </p>
          <p>
            For more information about Indian data protection laws, visit the{" "}
            <a
              href="https://www.meity.gov.in"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#007bff", textDecoration: "underline" }}
            >
              Ministry of Electronics and Information Technology (MeitY)
            </a>{" "}
            website.
          </p>
        </div>
      </div>

      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default PrivacyPolicy;
